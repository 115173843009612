const FormStep = Object.freeze({
    CONTACT_FORM: 0,
    MORE_INFO_FORM: 1,
})

const steps = Object.freeze(['Общие данные', 'Контактная информация'])

const headerOptions = {
    title: "Заявка на участие",
    description: "Оставьте все необходимые данные для регистрации вас как выступающего атлета."
}

export {
    steps,
    headerOptions,
    FormStep
}