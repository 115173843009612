import {FormStep} from "../utils/constants";

const FieldRequiredForm = Object.freeze({
    LAST_NAME: 'lastName',
    FIRST_NAME: 'firstName',
    FATHER_NAME: 'fatherName',
    WEIGHT: 'weight',
    YEAR: 'year',
    PHONE: 'phone',
    POLIT: 'polit'
})

const Rule = Object.freeze({
    NOT_NULL: 'not_null',
    BOOL: 'bool'
})

// Переименновать
const stepValidate = Object.freeze({
    [FormStep.CONTACT_FORM]: {
        [FieldRequiredForm.LAST_NAME]: 2,
        [FieldRequiredForm.FIRST_NAME]: 1,
        [FieldRequiredForm.FATHER_NAME]: 2,
        [FieldRequiredForm.WEIGHT]: Rule.NOT_NULL,
        [FieldRequiredForm.YEAR]: 1,
        [FieldRequiredForm.POLIT]: Rule.BOOL
    },
    [FormStep.MORE_INFO_FORM]: {
        [FieldRequiredForm.PHONE]: 5,
        [FieldRequiredForm.POLIT]: Rule.BOOL
    }
})

const SponsorsFormValidate = Object.freeze({
    [FieldRequiredForm.PHONE]: 5,
    [FieldRequiredForm.POLIT]: Rule.BOOL
})

const LENGTH_MESSAGE = 'Минимум'

const ErrorMessage = Object.freeze({
    [FieldRequiredForm.LAST_NAME]: `${LENGTH_MESSAGE} ${stepValidate[FormStep.CONTACT_FORM][FieldRequiredForm.LAST_NAME] + 1} символа`,
    [FieldRequiredForm.FIRST_NAME]: `${LENGTH_MESSAGE} ${stepValidate[FormStep.CONTACT_FORM][FieldRequiredForm.FIRST_NAME] + 1} символа`,
    [FieldRequiredForm.FATHER_NAME]: `${LENGTH_MESSAGE} ${stepValidate[FormStep.CONTACT_FORM][FieldRequiredForm.FATHER_NAME] + 1} символа`,
    [FieldRequiredForm.WEIGHT]: 'Выберите категорию',
    [FieldRequiredForm.YEAR]: `${LENGTH_MESSAGE} ${stepValidate[FormStep.CONTACT_FORM][FieldRequiredForm.YEAR] + 1} символа`,
    [FieldRequiredForm.PHONE]: 'Введите корректный номер телефона',
    [FieldRequiredForm.POLIT]: 'Примите политику'
})

export {
    FieldRequiredForm,
    SponsorsFormValidate,
    stepValidate,
    ErrorMessage,
    Rule,
}