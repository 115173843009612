import styled from 'styled-components'

const FormContainer = styled.div`
    max-width: 570px;
    margin: 0 auto;
    opacity: 0.7;
    transition: opacity 0.3s ease-in-out;

    &:hover {
        opacity: 1;
    };
`

export {
    FormContainer
}