import {useFormik} from "formik";

import {FormContext} from "./form-context";

const FormProvider = ({children}) => {
    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            fatherName: '',
            level: '',
            phone: '',
            city: '',
            year: '',
            sportOrg: '',
            weight: '',
            moreInfo: '',
            section: '',
            polit: true
        }
    });


    return (
        <FormContext.Provider value={formik}>
            {children}
        </FormContext.Provider>
    )
}

export {
    FormProvider
}

