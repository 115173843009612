import {FieldRequiredForm, stepValidate, Rule, ErrorMessage} from "./constants";

/**
 *
 * @param {string|number} value
 * @param {string|number} rule
 * @returns {boolean}
 */
const isValidField = (value, rule) => {
    // если число то проверяем длину поля.
    if(!isNaN(rule)) {
        return value.length > rule
    }

    // проверка на пустоты
    if(rule === Rule.NOT_NULL) {
        return !!value
    }

    if(rule === Rule.BOOL) {
        return value
    }

    throw new Error('not rules')
}

/**
 *
 * @param {number|object} step
 * @param {{values: {key: string}}} form
 * @returns {{}}
 */
const validateStep = (step, form) => {
    const {values} = form
    const validateFieldForm = typeof step === 'number' ? stepValidate[step] : step

    let isValidate = true

    const fieldsError = Object.entries(validateFieldForm).reduce((acc, [name, rule]) => {
        const value = values[name]

        const isValid = isValidField(value, rule)

        if (isValid === false) {
            isValidate = false
            return {
                ...acc,
                [name]: ErrorMessage[name]
            }
        }

        return acc
    }, {})

    return {
        isValidate,
        fieldsError
    }
}

export {
    validateStep
}