import {Paper} from '@mui/material';

import {SponsorsForm} from "./components";

import {FormContainer} from "./style";
import {Header} from "../header";
import {headerOptions} from "./utils/constants";
import {useState} from "react";
import {Thanks} from "../thanks";



const Sponsors = () => {
    const [showThanksPage, setShowThanksPage] = useState(false)

    return (
        <FormContainer>
            <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
               <Header {...headerOptions}/>
               {!showThanksPage && <SponsorsForm setShowThanksPage={setShowThanksPage}/>}
               {showThanksPage && <Thanks />}
            </Paper>
        </FormContainer>
    );
}

export { Sponsors }