import styled from "styled-components";

const Container = styled.div`
  position: relative;
`

const LogoRaven = styled.div`
    background-image: url("./logo.svg");
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    width: 173px;
    height: 150px;
  position: absolute;
  left: 10%;
  top: 64px;
  bottom: -120px;
  @media screen and (max-width: 980px) {
    display: none;
  }
`

const LogoBox = styled.div`
   background-color: #fff;
    background-image: url("./bars.jpg");
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    width: 140px;
    height: 140px;
  position: absolute;
  left: calc(10% + 200px);
  top: 64px;
  bottom: -120px;
    @media screen and (max-width: 1600px) {
        bottom: -290px;
        left: 11.5%;
    }
  @media screen and (max-width: 1080px) {
    display: none;
  }
`

export {
    Container,
    LogoBox,
    LogoRaven
}