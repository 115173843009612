import {useContext, useState} from 'react';
import {
    Checkbox,
    FormControlLabel,
    Grid,
    Box,
    Paper,
    Stepper,
    Step,
    StepLabel,
    Button,
    FormHelperText
} from "@mui/material";

import {MoreInfoForm, ContactForm} from "./components";
import {Header, Thanks} from "../../components";
import {FormContext} from "./context";
import {FormStep, headerOptions, steps} from "./utils/constants";
import {FormContainer} from "./style";
import {validateStep} from "./validate/validate";
import {FieldRequiredForm} from "./validate";
import sendData from "../../service/sendData";

function getStepContent(step, props) {
    switch (step) {
        case FormStep.CONTACT_FORM:
            return <ContactForm {...props} />;
        case FormStep.MORE_INFO_FORM:
            return <MoreInfoForm {...props} />;
        default:
            throw new Error('Unknown step');
    }
}

const Participants = () => {
    // TODO общий валид хук
    const [validate, setValidate] = useState({isValidate: true, fieldsError: {}})
    const [activeStep, setActiveStep] = useState(FormStep.CONTACT_FORM);
    const [showThanksPage, setShowThanksPage] = useState(false)

    const form = useContext(FormContext)

    const handleNext = async () => {
        const validateData = validateStep(activeStep, form)

        setValidate(validateData)

        if(validateData.isValidate && activeStep < steps.length - 1) {
            setActiveStep(activeStep + 1);
        }

        if(activeStep === steps.length - 1) {
           const data = await sendData('/send.php', form.values)

           if (data?.isSend) {
               setShowThanksPage(data?.isSend)
           }
        }
    };

    const handleBack = () => {
        setActiveStep(activeStep - 1);
    };

    return (
        <FormContainer>
            <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
                <Header {...headerOptions}/>

                <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>

                {showThanksPage && <Thanks />}

                {!showThanksPage &&
                    <>
                        {getStepContent(activeStep, {validate})}

                        <Grid style={{marginTop: '20px'}} item xs={12}>
                            <FormControlLabel
                                required
                                onChange={(e) => form.setFieldValue('polit', e.target.checked)}
                                checked={form?.values?.polit}
                                control={<Checkbox color="error" name="polit" value="yes" />}
                                label="Согласен с политикой конфиденциальности"
                            />
                            {validate?.fieldsError?.[FieldRequiredForm.POLIT] &&
                                <FormHelperText error>
                                    {validate.fieldsError[FieldRequiredForm.POLIT]}
                                </FormHelperText>
                            }
                        </Grid>

                        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            {activeStep !== 0 && (
                                <Button color="error" onClick={handleBack} sx={{ mt: 3, ml: 1 }}>
                                    Назад
                                </Button>
                            )}

                            <Button
                                color="error"
                                variant="contained"
                                onClick={handleNext}
                                sx={{ mt: 3, ml: 1 }}
                            >
                                {activeStep === steps.length - 1 ? 'Оставить заявку' : 'Далее'}
                            </Button>
                        </Box>
                    </>
                }
            </Paper>
        </FormContainer>
    );
}

export { Participants }