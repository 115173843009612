import {Link, Typography} from "@mui/material";

import {FooterWrapper} from "./style";


const Copyright = () => {
    return (
        <FooterWrapper>
            <Typography variant="body2" color="text.secondary" align="center">
                {'Copyright © '}
                <Link color="inherit" href="https://vk.com/ravens818">
                    Holmgard Ravens MC
                </Link>{' '}
                {new Date().getFullYear()}
                {'.'}
            </Typography>
        </FooterWrapper>
    );
}

export {
    Copyright
}