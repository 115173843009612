import {useContext} from 'react';
import {FormControl, MenuItem, Select, InputLabel, Grid, TextField} from '@mui/material'
import {FormContext} from "../context";
import {FieldRequiredForm} from "../validate";

const weights = [
    '20 кг','25 кг', '30 кг', '35кг', '40 кг', '45 кг', '50 кг', '55 кг', '60 кг', '65 кг', '70 кг', '75 кг', '80 кг', '85 кг', '90 кг', '90+ кг' ]

const levels = ['Новички (белые пояса, либо опыт борьбы до 2 лет)', 'Опытные (синие пояса, либо опыт борьбы до 5 лет)', 'Эксперты (пурпурный пояс и выше)']

const sections = ['GI', 'NO-GI', 'GI + NO-GI']

const ContactForm = ({validate}) => {
    const {
        values, setFieldValue
    } = useContext(FormContext)

    const {fieldsError} = validate

    const handlerChange = (e, prop) => {
        setFieldValue(prop, e.target.value)
    }

    return (
        <Grid container spacing={3}>
            <Grid item xs={12} sm={4}>
                <TextField
                    required
                    id="lastName"
                    name="lastName"
                    label="Фамилия"
                    error={fieldsError?.[FieldRequiredForm.LAST_NAME]}
                    helperText={fieldsError?.[FieldRequiredForm.LAST_NAME]}
                    value={values.lastName}
                    onChange={(e) => handlerChange(e, 'lastName')}
                    fullWidth
                    autoComplete="family-name"
                    variant="standard"
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <TextField
                    required
                    id="firstName"
                    error={fieldsError?.[FieldRequiredForm.FIRST_NAME]}
                    helperText={fieldsError?.[FieldRequiredForm.FIRST_NAME]}
                    name="firstName"
                    value={values.firstName}
                    onChange={(e) => handlerChange(e, 'firstName')}
                    label="Имя"
                    fullWidth
                    autoComplete="given-name"
                    variant="standard"
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <TextField
                    required
                    id="fatherName"
                    name="fatherName"
                    error={fieldsError?.[FieldRequiredForm.FATHER_NAME]}
                    helperText={fieldsError?.[FieldRequiredForm.FATHER_NAME]}
                    value={values.fatherName}
                    onChange={(e) => handlerChange(e, 'fatherName')}
                    label="Отчество"
                    fullWidth
                    autoComplete="father-name"
                    variant="standard"
                />
            </Grid>
            <Grid item xs={12} sm={12}>
                <FormControl variant="standard" fullWidth>
                    <InputLabel id="demo-simple-select-label">Уровень подготовки</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={values.level}
                        label="Уровень подготовки"
                        onChange={(_, {props}) => setFieldValue('level', props?.value)}
                    >
                        {levels.map((w, index) => <MenuItem key={index} value={w}>{w}</MenuItem>)}
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
                <FormControl variant="standard" fullWidth>
                    <InputLabel id="demo-simple-select-label">Раздел</InputLabel>
                    <Select
                        required
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={values.section}
                        label="Раздел"
                        onChange={(_, {props}) => setFieldValue('section', props?.value)}
                    >
                        {sections.map((w, index) => <MenuItem key={index} value={w}>{w}</MenuItem>)}
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
                <FormControl variant="standard" fullWidth>
                    <InputLabel id="demo-simple-select-label">Весовая каттегория</InputLabel>
                    <Select
                        required
                        error={fieldsError?.[FieldRequiredForm.WEIGHT]}
                        helperText={fieldsError?.[FieldRequiredForm.WEIGHT]}
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={values.weight}
                        label="Весовая каттегория"
                        onChange={(_, {props}) => setFieldValue('weight', props?.value)}
                    >
                        {weights.map((w, index) => <MenuItem key={index} value={w}>{w}</MenuItem>)}
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
                <TextField
                    required
                    id="year"
                    name="year"
                    error={fieldsError?.[FieldRequiredForm.YEAR]}
                    helperText={fieldsError?.[FieldRequiredForm.YEAR]}
                    value={values.year}
                    onChange={(e) => handlerChange(e, 'year')}
                    label="Год рождения"
                    fullWidth
                    variant="standard"
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <TextField
                    id="city"
                    name="city"
                    value={values.city}
                    onChange={(e) => handlerChange(e, 'city')}
                    label="Город"
                    fullWidth
                    autoComplete="city"
                    variant="standard"
                />
            </Grid>
            <Grid item xs={12} sm={12}>
                <TextField
                    id="sport-org"
                    name="sport-org"
                    value={values.sportOrg}
                    onChange={(e) => handlerChange(e, 'sportOrg')}
                    label="Спортиваный клуб (общество)"
                    autoComplete="sport-org"
                    fullWidth
                    variant="standard"
                />
            </Grid>
        </Grid>

    );
}

export {
    ContactForm
}