import styled from "styled-components";

const VideoWrapper = styled.div`
  video {
    width: 125%;
    height: 125vh;
    position: absolute;
    z-index: 1;
    left: -12.5%;
    top: 0;
  }
  .overlay {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.65);
  }
  @media screen and (max-width: 1379px) {
    .overlay {
      background-color: rgba(0, 0, 0, 0.65);
      background-image: url("./0Ky59g6YVmI.jpg");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      &:after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.65);
      }
    }
  }
`

export {
    VideoWrapper
}